<template>
  <v-app>
    <v-container
      id="terms"
      fluid
      tag="section"
      class="container-full-size"
    >
      <v-row class="terms-container">
        <v-col cols="12">
          <div class="terms-title">
            TERMOS E CONDIÇÕES GERAIS
          </div>
          <div class="terms-subtitle">
            TERMOS E CONDIÇÕES GERAIS DE USO DA PLATAFORMA DIGITAL “MKGEST”
          </div>
          <div class="terms-text terms-top">
            Estes termos e condições de uso aplicam-se aos serviços prestados pela empresa HARDWIRED - DIGITAL SOLUTIONS LDA, sociedade comercial por quotas, com o NIPC 517.985047, com o capital social de € 5.000,00 (cinco mil euros), proprietária de um software denominado MKGest, com sede em
          </div>
          <div class="terms-text">
            Avenida de Tibães, 1199, Vila Nova de Famalicão, 
          </div>
          <div class="terms-text">
            freguesia de Vale (S. Cosme), Telhado e Portela, 
          </div>
          <div class="terms-text">
            concelho de Vila Nova de Famalicão,
          </div>
          <div class="terms-subtitle">
            1. DO OBJETO
          </div>
          <div class="terms-text">
            A plataforma digital MKGEST caracteriza-se pela prestação dos seguintes serviços:
          </div>
          <div class="terms-text">
            - Disponibilização de uma plataforma de gestão online (Dashboard), personalizada compatível com o modo de trabalho do personal trainer, acompanhada de uma APP personalizada com a sua marca pessoal;
          </div>
          <div class="terms-text">
            - Disponibilização da funcionalidade de uma página principal com alertas (novos registos na app, envios de feedback, clientes com pagamentos pendentes, clientes aniversariantes, etc), Gestão de Clientes, Gestão de Planos de Treino, Gestão de Planos de Nutrição, Gestão da base de dados de Exercícios, Gestão da base de dados de Alimentos e Suplementos, além de um menu com Tutoriais explicativos de todas as funcionalidades. 
          </div>
          <div class="terms-text">
            - Criação de uma APP personalizada com a marca e logótipo do cliente, cores e imagens específicas, assim como outras pequenas personalizações, sendo utilizada exclusivamente com os clientes.
          </div>
          <div class="terms-text">
            Disponibilização da funcionalidade de uma página que permite a integração de pagamentos diretamente na aplicação, permitindo que os clientes efetuem o pagamento do serviço diretamente da APP, recebendo o dinheiro automaticamente na plataforma de pagamentos escolhida (integração com a Stripe e Viva Wallet).
          </div>
          <div class="terms-text">
            - Disponibilização da funcionalidade de criação de uma lista de funcionários com acesso à gestão online, no qual o administrador poderá configurar permissões de acesso às funcionalidades disponíveis.
          </div>
          <div class="terms-text">
            - Disponibilização de uma funcionalidade que permite o envio de notificações automáticas de aviso aos clientes, permitindo o envio de notificações personalizadas.
          </div>
          <div class="terms-text">
            - Disponibilização de uma funcionalidade que permite partilhar conteúdos adicionais na APP, tais como documentos com informações relevantes, vídeos com dicas, receitas, etc. 
          </div>
          <div class="terms-text">
            - Considera-se como fazendo parte da plataforma digital MKGEST, o endereço www.mkgest.com, bem como outros serviços digitais associados à empresa HARDWIRED - DIGITAL SOLUTIONS LDA.
          </div>
          <div class="terms-subtitle">
            2. DA ACEITAÇÃO DOS TERMOS E CONDIÇÕES GERAIS DE USO
          </div>
          <div class="terms-text">
            Todos aqueles que desejarem ter acesso aos serviços oferecidos através da plataforma digital MKGEST deverão, primeiramente, informar-se sobre os termos e condições de uso, os quais estão disponíveis para consulta através de link direto no próprio site.
          </div>
          <div class="terms-text">
            Ao utilizar a plataforma MKGEST, o utilizador aceita, integralmente, as normas presentes neste documento. Antes de iniciar qualquer navegação, deverá certificar-se de eventuais modificações ou atualizações ocorridas desde o último acesso.
          </div>
          <div class="terms-text">
            Caso não concorde com alguma das normas aqui descritas, poderá contactar o nosso serviço comercial expondo a situação.
          </div>
          <div class="terms-subtitle">
            3. DA NAVEGAÇÃO
          </div>
          <div class="terms-text">
            O proprietário da plataforma MKGEST compromete-se a utilizar todas as soluções técnicas à sua disposição para permitir o acesso ao serviço 24 (vinte e quatro) horas por dia, todos os dias do ano. No entanto, poderá, a qualquer momento, interromper, limitar ou suspender o acesso ao site ou a alguma das suas páginas, para fazer atualizações, modificações, ou qualquer outra ação que ache necessária.
          </div>
          <div class="terms-text">
            Os presentes termos e condições gerais de uso aplicam-se a todas as extensões do site MKGEST.COM, bem como às redes sociais e outras plataformas digitais geridas pela empresa.
          </div>
          <div class="terms-subtitle">
            4. DA GESTÃO DO SITE
          </div>
          <div class="terms-text">
            Para uma boa gestão, o proprietário da plataforma MKGEST poderá, a qualquer momento:
          </div>
          <div class="terms-text">
            a) Suspender, interromper ou limitar o acesso a todo ou apenas a parte de alguma das suas plataformas digitais;
          </div>
          <div class="terms-text">
            b) Remover toda a informação que possa perturbar a boa utilização, ou que não esteja em conformidade com a RGPD atualmente em vigor;
          </div>
          <div class="terms-text">
            c) Suspender o domínio para a realização de atualizações e/ou modificações.
          </div>
          <div class="terms-subtitle">
            5. DAS RESPONSABILIDADES
          </div>
          <div class="terms-text">
            O proprietário da plataforma MKGEST responsabiliza-se apenas pelas informações que forem diretamente divulgadas por ele. Quaisquer informações introduzidas pelos utilizadores, tanto em comentários como em perfis pessoais, são da inteira responsabilidade dos mesmos.
          </div>
          <div class="terms-text">
            O utilizador é responsável:
          </div>
          <div class="terms-text">
            a) Pela correta utilização do site e das redes sociais, prezando a boa convivência, o respeito e a cordialidade com os demais utilizadores;
          </div>
          <div class="terms-text">
            b) Pelo cumprimento das regras descritas neste documento.
          </div>
          <div class="terms-text">
            O proprietário não será responsável:
          </div>
          <div class="terms-text">
            a) Pelas características intrínsecas da internet, principalmente relativo à fiabilidade e procedência das informações;
          </div>
          <div class="terms-text">
            b) Pelos conteúdos ou atividades ilícitas praticadas através das diversas plataformas digitais.
          </div>
          <div class="terms-subtitle">
            6. DOS LINKS EXTERNOS
          </div>
          <div class="terms-text">
            A plataforma MKGEST pode conter links externos direcionando o utilizador para outras páginas de internet, sobre as quais não temos qualquer controlo. Apesar das verificações prévias e regulares realizadas pelo proprietário, este isenta-se de qualquer responsabilidade sobre o conteúdo encontrado nestes sites e/ou serviços.
          </div>
          <div class="terms-text">
            Poderão ser incluídos links nas páginas e nos documentos MKGEST, desde que não sirvam para fins comerciais e/ou publicitários. Esta inclusão está pendente de autorização prévia do proprietário.
          </div>
          <div class="terms-text">
            Não será autorizada a inserção de publicidade a páginas que divulguem quaisquer tipos de informações ilícitas, violentas, polémicas, pornográficas, xenófobas, discriminatórias e/ou ofensivas.
          </div>
          <div class="terms-subtitle">
            7. DOS DIREITOS DE AUTOR
          </div>
          <div class="terms-text">
            A estrutura da plataforma MKGEST, bem como os textos, gráficos, imagens, sons, vídeos e demais aplicações informáticas que o compõem são propriedade da MKGEST.
          </div>
          <div class="terms-text">
            Qualquer representação, reprodução, adaptação ou exploração parcial ou total dos conteúdos, marcas e serviços disponibilizados, sem autorização prévia expressa e escrita do proprietário, é estritamente proibida. Estão excluídos os conteúdos apresentados como sendo livres de direitos autorais.
          </div>
          <div class="terms-text">
            O acesso aos mesmos não reflete, para o utilizador, qualquer direito de propriedade intelectual.
          </div>
          <div class="terms-subtitle">
            8. DA POLÍTICA DE PRIVACIDADE
          </div>
          <div class="terms-text">
            8.1 Todos os dados pessoais fornecidos pelo utilizador aquando do seu acesso ao site ou às redes sociais, permanecerão sigilosos e não serão transmitidos a qualquer outra empresa.
          </div>
          <div class="terms-text">
            8.2 É da responsabilidade do proprietário a garantia de confidencialidade dos dados pessoais recolhidos, devendo proteger a plataforma contra tentativas de violação ou aceso indevido à sua base de dados.
          </div>
          <div class="terms-text">
            8.3 A plataforma poderá utilizar técnicas de “cookies” para analisar as estatísticas de navegação do utilizador, podendo ser fornecidos dados sobre o dispositivo do utilizador e qual a sua localização. Esta recolha de informação servirá para melhorar a navegação, apresentando serviços personalizados ao utilizador.
          </div>
          <div class="terms-text">
            8.4 O utilizador poderá desativar as técnicas de “cookies” no seu próprio aparelho. Por outro lado, a desativação das técnicas de “cookies” poderá limitar a disponibilidade de algumas ferramentas e serviços.
          </div>
          <div class="terms-text">
            8.5 Em caso necessário, e de acordo com as leis de proteção de dados, obterá todos os consentimentos e direitos necessários e, proporcionará toda a informação e avisos necessários aos titulares dos dados para:
          </div>
          <div class="terms-text">
            - entregar-nos os dados pessoais do cliente;
          </div>
          <div class="terms-text">
            - processar os dados pessoais do cliente para os fins estabelecidos nesta licença; e
          </div>
          <div class="terms-text">
            - comunicar os dados pessoais do cliente a: (a) a nossos agentes, fornecedores de serviços e outras companhias dentro do grupo de companhias  (b) a agências que o solicitem ao abrigo da lei; (c) a qualquer outra pessoa para cumprir com nossas obrigações legais, incluídos os relatórios regulamentares ou legais; e (d) a qualquer outra pessoa que tenha o direito legal de exigir a comunicação da informação, inclusive quando os destinatários dos dados pessoais do cliente se encontrem fora da Área Económica Europeia.
          </div>
          <div class="terms-text">
            8.6 Tendo em conta a natureza do tratamento, a MKGEST implementou um conjunto medidas técnicas e organizativas apropriadas, na medida do possível, para cumprir com a obrigação do Utilizador de responder aos pedidos de potenciais clientes para exercer os direitos dos donos dos dados; e prestar a devida assistência para garantir o cumprimento de suas obrigações relacionadas com:
          </div>
          <div class="terms-text">
            - Notificações às autoridades supervisoras;
          </div>
          <div class="terms-text">
            - Consultas prévias com as autoridades supervisoras;
          </div>
          <div class="terms-text">
            - Comunicação de qualquer violação aos titulares dos dados; e
          </div>
          <div class="terms-text">
            - Avaliações de impacto de privacidade.
          </div>
          <div class="terms-text">
            8.7 A MKGEST, quanto a pessoal ao seu serviço que trate dados do cliente, impôs  medidas para:
          </div>
          <div class="terms-text">
            - Garantir a fiabilidade de qualquer funcionário que possa ter acesso aos dados responsabilidade do Utilizador;
          </div>
          <div class="terms-text">
            - Garantir que o acesso aos dados responsabilidade do Utilizador estejam estritamente limitado àquelas pessoas que precisem conhecer e / ou aceder aos dados conforme ao propósito desta licença; e
          </div>
          <div class="terms-text">
            - Garantir que as pessoas autorizadas a processar os dados responsabilidade do Utilizador se tenham comprometido à confidencialidade ou que estejam baixo a obrigação estatutária de confidencialidade.
          </div>
          <div class="terms-text">
            8.8 A MKGEST implementará e manterá as medidas de segurança técnicas e organizativas apropriadas aos riscos apresentados pela atividade de tratamento de dados, para proteger os dados responsabilidade do Utilizador, de tratamentos não autorizados ou ilegais, contra perda, destruição, dano ou divulgação acidental. Ditas medidas incluem, sem limitação, as medidas de segurança.
          </div>
          <div class="terms-text">
            8.9 Sujeito a qualquer obrigação existente de confidencialidade devida a outras partes, será proporcionado toda a informação razoavelmente necessária para demonstrar o cumprimento das obrigações estabelecidas, que pode incluir um resumo de qualquer relatório de auditoria de segurança de terceiros disponível, ou deverá, a seu exclusivo custo e despesa.
          </div>
          <div class="terms-subtitle">
            9. DO ATENDIMENTO AO UTILIZADOR
          </div>
          <div class="terms-text">
            Em caso de dúvidas, sugestões ou dificuldades na utilização da plataforma MKGEST, o utilizador poderá contactar diretamente o departamento comercial através do endereço de email dev@mkgest.com.
          </div>
          <div class="terms-text">
            Em alternativa, poderá enviar a sua exposição diretamente para a morada identificada no início do documento.
          </div>
          <div class="terms-subtitle">
            10. DAS SANÇÕES
          </div>
          <div class="terms-text">
            O proprietário da plataforma MKGEST poderá, a qualquer altura, advertir, suspender ou impedir o acesso do utilizador:
          </div>
          <div class="terms-text">
            a) Que não cumpra o explanado anteriormente;
          </div>
          <div class="terms-text">
            b) Que não cumpra os seus deveres de utilizador;
          </div>
          <div class="terms-text">
            c) Que pratique atos fraudulentos ou dolosos a terceiros;
          </div>
          <div class="terms-text">
            d) Que tenha um comportamento suscetível de resultar em ofensa ou dano a terceiros.
          </div>
          <div class="terms-subtitle">
            11. DAS ALTERAÇÕES
          </div>
          <div class="terms-text">
            A presente versão dos Termos e Condições Gerais de Uso da plataforma MKGEST foi elaborado em 29 de abril de 2024.
          </div>
          <div class="terms-text">
            O proprietário reserva-se no direito de modificar, a qualquer momento e sem aviso prévio, o presente documento.
          </div>
          <div class="terms-subtitle">
            12. DO DIREITO APLICÁVEL E DO FORO
          </div>
          <div class="terms-text">
            Para a resolução de quaisquer conflitos que poderão existir, as ações de litígio serão tratadas pela Comarca de Braga- Secção de Vila Nova de Famalicão.
          </div>
          <div class="pt-6 terms-subtitle">
            <v-checkbox
              v-model="termsValue"
              label="Li e concordo com todos os termos e condições."
              class="form-field-checkbox"
              hide-details
            />
          </div>
          <div class="pt-8 pb-10 row-flex-align-center">
            <v-btn
              color="success"
              @click="confirm()"
              :disabled="!termsValue"
            >
              {{ str ? str['confirm'] : '' }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<style scoped>
  .terms-container {
    max-width: 600px;
    margin: 0 auto;
  }

  .terms-title {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 20px;
  }

  .terms-subtitle {
    padding-top: 15px;
    font-size: 13px;
    font-weight: bold;
    text-align: left;
  }

  .terms-text {
    padding-top: 5px;
    font-size: 12px;
    text-align: justify;
  }

  .terms-top {
    padding-top: 20px;
  }
</style>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'
  import moment from 'moment'

  export default {
    data () {
      const user = Utils.getUser()
      return {
        user: user,
        str: window.strings,
        termsValue: false,
      }
    },
    beforeMount: function () {
      if (!this.user) {
        this.$router.replace({ path: '/' })
      }
    },
    methods: {
      confirm: function () {
        const self = this
        if (this.termsValue) {
          this.$isLoading(true)
          Api.updateUser({
            accept_terms_date: moment.utc().format('YYYY-MM-DDTHH:mm:ss')
          }, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.$router.replace({ path: '/home' })
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
    },
  }
</script>
